import React, { Component } from 'react';
import { RefundAmount, RefundBottomSheetDetails, RefundBreakdown, RefundDateGroup } from '../component';
import RefundComponentRow from './RefundComponentRow';

interface RefundBottomSheetProps {
    refundBottomSheetDetails: RefundBottomSheetDetails;
}

class RefundBottomSheet extends Component<RefundBottomSheetProps, {}> {

    _generateRefundComponentRow(refundAmount: RefundAmount, refundTotal: string) {
        return <RefundComponentRow amount={refundAmount} nameLabel={refundTotal} key={refundTotal} />
    }

    _generateRefundSectionHeading(refundDate: string) {
        return <>
            <pui-text fontWeight="bold" input={refundDate} textSize='medium' textAlign="left" />
            <pui-divider spacingTop='small' spacingBottom='small' />
        </>;
    }

    /**
     * Check if the refund breakdown is valid. A valid refund breakdown has at least one item refund, or other refund, or tax refund.
     * @param refundBreakdown the refund breakdown to check.
     * @returns true if the refund breakdown is valid, false otherwise.
     */
    _isValidRefundBreakdown(refundBreakdown: RefundBreakdown) {
        return refundBreakdown 
            && ((refundBreakdown.itemRefunds && refundBreakdown.itemRefunds.length > 0) 
                || refundBreakdown.otherRefund
                || refundBreakdown.taxRefund);
    }

    /**
     * Generate the refund rows for each refund breakdown. 
     * @param refundBreakdown the refund breakdown to generate the rows for.
     * @returns the refund rows for the given refund breakdown.
     */
    _generateRefundBreakdown(refundBreakdown: RefundBreakdown) {
        return (
            <>
                {
                    refundBreakdown.itemRefunds.map(itemRefund => this._generateRefundComponentRow(itemRefund.refundAmount, itemRefund.refundLabel))
                }
                {
                    refundBreakdown.otherRefund?.refundAmount && this._hasPositiveRefundAmount(refundBreakdown.otherRefund?.refundAmount) && refundBreakdown.otherRefund?.refundLabel?
                        this._generateRefundComponentRow(refundBreakdown.otherRefund?.refundAmount, refundBreakdown.otherRefund?.refundLabel) : null
                }
                {
                    refundBreakdown.shippingRefund?.refundAmount && this._hasPositiveRefundAmount(refundBreakdown.shippingRefund?.refundAmount) && refundBreakdown.shippingRefund?.refundLabel?
                        this._generateRefundComponentRow(refundBreakdown.shippingRefund?.refundAmount, refundBreakdown.shippingRefund?.refundLabel) : null
                }
                {
                    refundBreakdown.taxRefund?.refundAmount && this._hasPositiveRefundAmount(refundBreakdown.taxRefund?.refundAmount) && refundBreakdown.taxRefund?.refundLabel?
                        this._generateRefundComponentRow(refundBreakdown.taxRefund?.refundAmount, refundBreakdown.taxRefund?.refundLabel) : null
                }
            </>
        )
    }

    /**
     * Generate the refund section for a given refund date group.
     * @param refundDateGroup the refund date group to generate the section for.
     * @returns the refund section for the given refund date group.
     */
    _generateRefundSection(refundDateGroup: RefundDateGroup) {

        let allRefundBreakdowns = refundDateGroup.refundBreakdowns;
        let validRefundBreakdowns = allRefundBreakdowns.filter(refundBreakdown => this._isValidRefundBreakdown(refundBreakdown));
        return (
            <>
                {this._generateRefundSectionHeading(refundDateGroup.refundDate)}
                {validRefundBreakdowns.map(refundBreakdown => this._generateRefundBreakdown(refundBreakdown))}
            </>
        )
    }

    _findAndRemoveByKey(array: any[], key: any) {
        let result = undefined;
        const pos = array.findIndex(el => el.name === key);
        if (pos >= 0) {
            result = array.splice(pos, 1)[0];
        }
        return result;
    }

    _hasPositiveRefundAmount(refundAmount: RefundAmount) {
        return refundAmount && refundAmount.amount && (parseFloat(refundAmount.amount) > 0)
    }

    render() {
        const refundBottomSheetDetails = this.props.refundBottomSheetDetails;
        return (
            <>
                <pui-heading input={this.props.refundBottomSheetDetails.refundDetails} textSize='large' spacingTop="small" spacingBottom="medium" />

                {refundBottomSheetDetails.refundDateGroups.map(refundDateGroup => this._generateRefundSection(refundDateGroup))}
                
                <RefundComponentRow fontWeight="bold" noDivider={true} amount={this.props.refundBottomSheetDetails.refundTotalRow.refundAmount} nameLabel={this.props.refundBottomSheetDetails.refundTotalRow.refundLabel} />
            </>
        )
    }
}

export default RefundBottomSheet;